exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-mdx": () => import("./../../../src/pages/about/index.mdx" /* webpackChunkName: "component---src-pages-about-index-mdx" */),
  "component---src-pages-about-portfolio-tsx": () => import("./../../../src/pages/about/portfolio.tsx" /* webpackChunkName: "component---src-pages-about-portfolio-tsx" */),
  "component---src-pages-about-uses-mdx": () => import("./../../../src/pages/about/uses.mdx" /* webpackChunkName: "component---src-pages-about-uses-mdx" */),
  "component---src-pages-awesomes-tsx": () => import("./../../../src/pages/awesomes.tsx" /* webpackChunkName: "component---src-pages-awesomes-tsx" */),
  "component---src-pages-category-name-tsx": () => import("./../../../src/pages/{Category.name}.tsx" /* webpackChunkName: "component---src-pages-category-name-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-notice-mdx": () => import("./../../../src/pages/legal-notice.mdx" /* webpackChunkName: "component---src-pages-legal-notice-mdx" */),
  "component---src-pages-lib-generative-tsx": () => import("./../../../src/pages/lib/generative.tsx" /* webpackChunkName: "component---src-pages-lib-generative-tsx" */),
  "component---src-pages-lib-index-mdx": () => import("./../../../src/pages/lib/index.mdx" /* webpackChunkName: "component---src-pages-lib-index-mdx" */),
  "component---src-pages-lib-inspiration-tsx": () => import("./../../../src/pages/lib/inspiration.tsx" /* webpackChunkName: "component---src-pages-lib-inspiration-tsx" */),
  "component---src-pages-lib-mixtapes-mdx": () => import("./../../../src/pages/lib/mixtapes.mdx" /* webpackChunkName: "component---src-pages-lib-mixtapes-mdx" */),
  "component---src-pages-lib-weeklymood-tsx": () => import("./../../../src/pages/lib/weeklymood.tsx" /* webpackChunkName: "component---src-pages-lib-weeklymood-tsx" */),
  "component---src-pages-lib-wishlist-tsx": () => import("./../../../src/pages/lib/wishlist.tsx" /* webpackChunkName: "component---src-pages-lib-wishlist-tsx" */),
  "component---src-pages-notebook-tsx": () => import("./../../../src/pages/notebook.tsx" /* webpackChunkName: "component---src-pages-notebook-tsx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-tutorials-tsx": () => import("./../../../src/pages/tutorials.tsx" /* webpackChunkName: "component---src-pages-tutorials-tsx" */),
  "component---src-pages-writing-tsx": () => import("./../../../src/pages/writing.tsx" /* webpackChunkName: "component---src-pages-writing-tsx" */),
  "component---src-templates-awesome-tsx-content-file-path-content-awesome-demo-index-mdx": () => import("./../../../src/templates/awesome.tsx?__contentFilePath=/vercel/path0/content/awesome/demo/index.mdx" /* webpackChunkName: "component---src-templates-awesome-tsx-content-file-path-content-awesome-demo-index-mdx" */),
  "component---src-templates-garden-tsx-content-file-path-content-garden-2022-01-01-demo-1-index-mdx": () => import("./../../../src/templates/garden.tsx?__contentFilePath=/vercel/path0/content/garden/2022-01-01--demo-1/index.mdx" /* webpackChunkName: "component---src-templates-garden-tsx-content-file-path-content-garden-2022-01-01-demo-1-index-mdx" */),
  "component---src-templates-garden-tsx-content-file-path-content-garden-2022-01-01-demo-2-index-mdx": () => import("./../../../src/templates/garden.tsx?__contentFilePath=/vercel/path0/content/garden/2022-01-01--demo-2/index.mdx" /* webpackChunkName: "component---src-templates-garden-tsx-content-file-path-content-garden-2022-01-01-demo-2-index-mdx" */),
  "component---src-templates-garden-tsx-content-file-path-content-garden-2022-01-01-demo-3-index-mdx": () => import("./../../../src/templates/garden.tsx?__contentFilePath=/vercel/path0/content/garden/2022-01-01--demo-3/index.mdx" /* webpackChunkName: "component---src-templates-garden-tsx-content-file-path-content-garden-2022-01-01-demo-3-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-portfolio-demo-a-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/vercel/path0/content/portfolio/demo-a/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-portfolio-demo-a-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-portfolio-demo-b-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/vercel/path0/content/portfolio/demo-b/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-portfolio-demo-b-index-mdx" */),
  "component---src-templates-prose-tsx-content-file-path-content-writing-2022-01-04-demo-prose-index-mdx": () => import("./../../../src/templates/prose.tsx?__contentFilePath=/vercel/path0/content/writing/2022-01-04--demo-prose/index.mdx" /* webpackChunkName: "component---src-templates-prose-tsx-content-file-path-content-writing-2022-01-04-demo-prose-index-mdx" */),
  "component---src-templates-prose-tsx-content-file-path-content-writing-2022-01-04-demo-prose-index-pt-mdx": () => import("./../../../src/templates/prose.tsx?__contentFilePath=/vercel/path0/content/writing/2022-01-04--demo-prose/index.pt.mdx" /* webpackChunkName: "component---src-templates-prose-tsx-content-file-path-content-writing-2022-01-04-demo-prose-index-pt-mdx" */),
  "component---src-templates-prose-tsx-content-file-path-content-writing-2022-01-05-hello-index-fr-mdx": () => import("./../../../src/templates/prose.tsx?__contentFilePath=/vercel/path0/content/writing/2022-01-05--hello/index.fr.mdx" /* webpackChunkName: "component---src-templates-prose-tsx-content-file-path-content-writing-2022-01-05-hello-index-fr-mdx" */),
  "component---src-templates-prose-tsx-content-file-path-content-writing-2022-01-05-hello-index-mdx": () => import("./../../../src/templates/prose.tsx?__contentFilePath=/vercel/path0/content/writing/2022-01-05--hello/index.mdx" /* webpackChunkName: "component---src-templates-prose-tsx-content-file-path-content-writing-2022-01-05-hello-index-mdx" */),
  "component---src-templates-prose-tsx-content-file-path-content-writing-2022-01-05-hello-index-pt-mdx": () => import("./../../../src/templates/prose.tsx?__contentFilePath=/vercel/path0/content/writing/2022-01-05--hello/index.pt.mdx" /* webpackChunkName: "component---src-templates-prose-tsx-content-file-path-content-writing-2022-01-05-hello-index-pt-mdx" */),
  "component---src-templates-tutorial-tsx-content-file-path-content-writing-2022-01-01-demo-tutorial-1-index-mdx": () => import("./../../../src/templates/tutorial.tsx?__contentFilePath=/vercel/path0/content/writing/2022-01-01--demo-tutorial-1/index.mdx" /* webpackChunkName: "component---src-templates-tutorial-tsx-content-file-path-content-writing-2022-01-01-demo-tutorial-1-index-mdx" */),
  "component---src-templates-tutorial-tsx-content-file-path-content-writing-2022-01-01-demo-tutorial-1-index-pt-mdx": () => import("./../../../src/templates/tutorial.tsx?__contentFilePath=/vercel/path0/content/writing/2022-01-01--demo-tutorial-1/index.pt.mdx" /* webpackChunkName: "component---src-templates-tutorial-tsx-content-file-path-content-writing-2022-01-01-demo-tutorial-1-index-pt-mdx" */),
  "component---src-templates-tutorial-tsx-content-file-path-content-writing-2022-01-02-demo-tutorial-2-index-mdx": () => import("./../../../src/templates/tutorial.tsx?__contentFilePath=/vercel/path0/content/writing/2022-01-02--demo-tutorial-2/index.mdx" /* webpackChunkName: "component---src-templates-tutorial-tsx-content-file-path-content-writing-2022-01-02-demo-tutorial-2-index-mdx" */),
  "component---src-templates-tutorial-tsx-content-file-path-content-writing-2022-01-02-demo-tutorial-2-index-pt-mdx": () => import("./../../../src/templates/tutorial.tsx?__contentFilePath=/vercel/path0/content/writing/2022-01-02--demo-tutorial-2/index.pt.mdx" /* webpackChunkName: "component---src-templates-tutorial-tsx-content-file-path-content-writing-2022-01-02-demo-tutorial-2-index-pt-mdx" */),
  "component---src-templates-tutorial-tsx-content-file-path-content-writing-2022-01-03-demo-tutorial-3-index-mdx": () => import("./../../../src/templates/tutorial.tsx?__contentFilePath=/vercel/path0/content/writing/2022-01-03--demo-tutorial-3/index.mdx" /* webpackChunkName: "component---src-templates-tutorial-tsx-content-file-path-content-writing-2022-01-03-demo-tutorial-3-index-mdx" */)
}

